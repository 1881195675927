::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar {
    right: 0;
    width: 6px;
    height: 6px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 8px;
    width: 6px;
    height: 6px;
  }